.Home-container-top {
  width: 100%;
  height: calc(100vh - 60px);
}

.Home-container-top section video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Home-container-mid {
  width: 100%;
}

.Home-container-mid img {
  width: 100%;
}

.Home-container-button-inset {
  background-color: rgb(41, 4, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
}

.Home-container-partners-inset {
  background-color: rgb(34, 20, 6);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px 40px;
}

.Home-container-partners-inset img {
  width: 60px
}

.Home-inset-text-white {
  font-family: 'Titillium Web', sans-serif;
  font-size: 1.1rem;
  font-weight: 200;
  color: rgb(255,255,255);
  letter-spacing: 2px;
}

.Button-homepage {
  background: none;
  border: 1px solid rgb(255,255,255);
  padding: .7rem 1rem;
  color:rgb(212, 212, 212);
  cursor: pointer;
  outline: none;
  transition: .3s;
}

.Button-homepage:hover {
  border: 1px solid rgb(172, 119, 34);
}

.Home-container-creator-musician {
  background: rgb(166,68,7);
  background: linear-gradient(0deg, rgba(166,68,7,1) 0%, rgba(207,126,7,1) 48%, rgba(166,68,7,1) 100%);
  width: 100%;
  padding: 40px 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff
}

.Home-container-creator-musician img {
  width: 80%
}

.Home-container-creator-musician-title {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-weight: 800;
  line-height: 80px;
}

.Home-container-roadmap {
  background: rgb(47,28,16);
  background: linear-gradient(0deg, rgba(47,28,16,1) 0%, rgba(75,34,9,1) 48%, rgba(47,28,16,1) 100%);
  width: 100%;
  padding: 30px 80px 50px 80px;
}

.Home-container-roadmap-tiles {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: #fff
}

.Home-container-roadmap-jazzy {
  width: 22%;
  background-color: rgb(58,28,10);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  min-height: 300px;
  padding: 24px;
}

.Home-container-roadmap-jelly {
  width: 22%;
  background-color: rgb(84,37,10);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  min-height: 300px;
  padding: 24px
}

.Home-container-roadmap-juicy {
  width: 22%;
  background-color: rgb(114,48,8);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  min-height: 300px;
  padding: 24px
}

.Home-container-roadmap-jumbo {
  width: 22%;
  background-color: rgb(148,61,8);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  min-height: 300px;
  padding: 24px
}

.Home-container-roadmap-title-title {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2rem;
}

.Home-container-roadmap-title-title-release {
  display: flex;
  justify-content: center;
  font-size: 1rem;
  color: #a28343;
  padding: 10px 0;
}

.Home-container-roadmap-title {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-weight: 400;
  color: rgb(138,95,8);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.6rem;
}

.Home-container-roadmap-tiles ul li {
  font-family: 'Titillium Web', sans-serif;
  font-size: .9rem;
  font-weight: 400;
  color: rgb(207, 196, 177);
  padding: .3rem 0;
}

.Home-container-roadmap-tiles ul li:nth-child(even) {
  color: rgb(213, 188, 145)
}

@media screen and (max-width: 480px) {
  .Home-container-top{
    margin-top: 15%;
  }
}