.SignUp-container {
  width: 70%; 
  margin: 4rem auto;
  display: flex; 
  flex-direction: row; 
  justify-content: center; 
}

.SignUp-leftCol {
  flex-basis: 0;
  flex-grow: 2;
  order: 1;
  padding-right: 2rem;
}

.SignUp-rightCol {
  flex-basis: 0;
  flex-grow: 2;
  order: 2;
  padding-left: 1rem
}

.SignUp-logo-container {
  display: flex; 
  flex-direction: column;
  justify-content: center; 
  align-items: center;
}

.SignUp-logo-container img {
  max-width: 60%;
}

.SignUp-fade-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.SignUp-fade-element {
  position: absolute;
  width: 100%;
  z-index: 10;
  top: 40%;
  opacity: 0;
  transition: opacity 0.5s;
}

.SignUp-fade-element-show {
  opacity: 1;
}

.SignUp-help-container {
  background: var(--byowave-panel-bg-colour);
  color: var(--byowave-panel-text-colour);
  border: 1px solid var(--byowave-alert-colour);
  border-radius: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1.2rem;
  box-shadow: rgba(0, 0, 0, 0.4) 3px 3px 5px;
}

.SignUp-help-container p {
  margin: 0
}

.SignUp-help-container h3 {
  color: var(--byowave-heading-text-colour);
  margin-bottom: .6rem;
}

@media screen and (max-width: 1024px) {
  .SignUp-container {
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  .SignUp-container {
    flex-direction: column; 
    width: 95%;
  }
  .SignUp-rightCol {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .SignUp-container {
    flex-direction: column; 
    margin-top: 6rem
  }

  .SignUp-leftCol {
    flex-grow: 3;
    order: 2;
    padding: 0;
  }
  
  .SignUp-rightCol {
    display: none;
  }
}