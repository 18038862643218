.App-header {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: rgb(24, 23, 29);
  height: 60px;
  padding: 0 10px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 50
}

.App-header-icon-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%
}

.App-header-icon-layout {
  display: flex;
  align-items: center;
  height: 100%
}

.Button-header {
  height: 34px;
  background: none;
  border: 1px solid rgb(40, 49, 93);
  padding: 0 1rem;
  color:var(--byowave-cta-colour);
  cursor: pointer;
  outline: none;
  transition: .3s;
  margin-left: 1rem
}

.Button-header:hover {
  background-color: rgb(39, 37, 60);
}

.App-header-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  color: varrgb(--byowave-cta-colour);
}

.App-header-icon span {
  font-size: 1rem;
  padding-left: 6px;
}

.App-header-icon i {
  font-size: 14px;
  color: var(--byowave-cta-colour);
}

.App-header-logo-container {
  display: flex; 
  flex-direction: column;
  width: 40px;
  justify-content: center; 
  align-items: center;
  margin-right: .2rem;
}

.App-header-basket-icon-badge {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 40%;
  font-size: .7rem;
  background-color: var(--byowave-cta-colour);
  color: var(--byowave-cta-text-colour);
  top: 7px;
  left: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1024px) {

}

@media screen and (max-width: 768px) {

}

@media screen and (max-width: 480px) {

}
