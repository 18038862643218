.BasketButton-container {
    display: flex;
    align-items: center;
}

.BasketButton-amount__standard {
    background-color: var(--byowave-custom-control-bg-colour);
    padding: .6rem 1.2rem;
}

.BasketButton-decrement__standard {
    border-radius: .5rem 0 0 .5rem !important;
}

.BasketButton-increment__standard {
    border-radius: 0 .5rem .5rem 0 !important;
}

.BasketButton-amount__tiny {
    background-color: var(--byowave-custom-control-bg-colour);
    padding: .3rem .8rem;
}

.BasketButton-decrement__tiny {
    border-radius: .3rem 0 0 .3rem !important;
    padding: .3rem !important;
    font-size: .9rem !important;
    min-width: 20px;
}

.BasketButton-increment__tiny {
    border-radius: 0 .3rem .3rem 0 !important;
    padding: .3rem !important;
    font-size: .9rem !important;
    min-width: 20px;
}
