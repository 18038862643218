.Downloads-container {
  width: 80%; 
  margin: 2rem auto;
}

.Downloads-tabs-container {
  width: 100%;
  display: flex;
  margin-top: 1rem;;
}

.Downloads-tab {
  border-radius: 20px 20px 0 0;
  background-color: var(--byowave-tab-bg-colour);
  color: var(--byowave-tab-text-colour);
  padding: 1rem;
  text-align: center;
  cursor: pointer
}

.Downloads-tab:hover {
  color: var(--byowave-tab-selected-text-colour);
}

.Downloads-tab__selected {
  border-radius: 20px 20px 0 0;
  background-color: var(--byowave-tab-selected-bg-colour);
  color: var(--byowave-tab-selected-text-colour);
  padding: 1rem;
  text-align: center;
}

.Downloads-panel-container {
  background-color: var(--byowave-tab-selected-bg-colour);
  padding: 1rem;
}

.Downloads-documents-container {
  padding: 2rem;
}

.Downloads-documents-category-container {
  padding-bottom: 1.4rem; 
  margin-bottom: 2rem;
  border-bottom: 1px dashed var(--byowave-cta-dark-colour)
}

.Downloads-document-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .6rem;
  margin-top: 1rem
}

.Downloads-document-item button.Button-icon-small i {
  font-size: 2rem
}

.Downloads-document-item-title {
  color: var(--byowave-heading-text-colour);
  font-weight: bold;
}

.Downloads-document-item-spinner {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-animation:spin 1s linear infinite;
  -moz-animation:spin 1s linear infinite;
  animation:spin 1s linear infinite;
}

.Downloads-document-item-spinner i {
  width: auto;
  color: var(--byowave-cta-colour);
  font-size: 2rem;
}