.SignIn-container {
  width: 70%; 
  margin: 4rem auto;
  display: flex; 
  flex-direction: row; 
  justify-content: center;
}

.SignIn-leftCol {
  flex-basis: 0;
  flex-grow: 2;
  order: 1;
  padding-right: 2rem;
}

.SignIn-rightCol {
  flex-basis: 0;
  flex-grow: 2;
  order: 2;
  padding-left: 1rem
}

.SignIn-logo-container {
  display: flex; 
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center; 
  align-items: center;
}

.SignIn-logo-container img {
  max-width: 60%;
}

@media screen and (max-width: 1024px) {
  .SignIn-container {
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  .SignIn-container {
    flex-direction: column;
    width: 90%;
  }
  .SignIn-rightCol {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .SignIn-container {
    flex-direction: column; 
    margin-top: 6rem
  }

  .SignIn-leftCol {
    flex-grow: 3;
    order: 2;
    padding: 0;
  }
  
  .SignIn-rightCol {
    display: none;
  }
}