.RadioButton-container__small {
  margin: 0 .4rem 0 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.RadioButton-outer-circle__small {
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  border: 2px solid var(--byowave-custom-control-border-colour);
  background-color: var(--byowave-custom-control-bg-colour);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.1s linear;
}

.RadioButton-inner-circle__small {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--byowave-custom-control-inner-colour);
  transition: all 0.1s linear;
}

.RadioButton-unselected__small {
  border: 2px solid var(--byowave-custom-control-border-colour);
}

.RadioButton-unselected-circle__small {
  width: 0;
  height: 0;
}

.RadioButton-label__small {
  color: var(--byowave-custom-control-text-colour);
  padding-right: 8px;
  margin-left: 8px;
  font-size: .9rem;
}

/* LARGE VERSION */

.RadioButton-container__big {
  margin: 0 .4rem 0 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.RadioButton-outer-circle__big {
  width: 26px;
  height: 26px;
  min-width: 26px;
  min-height: 26px;
  border: 3px solid var(--byowave-custom-control-border-colour);
  background-color: var(--byowave-custom-control-bg-colour);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.1s linear;
}

.RadioButton-inner-circle__big {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--byowave-custom-control-inner-colour);
  transition: all 0.1s linear;
}

.RadioButton-unselected__big {
  border: 3px solid var(--byowave-custom-control-border-colour);
}

.RadioButton-unselected-circle__big {
  width: 0;
  height: 0;
}

.RadioButton-label__big {
  color: var(--byowave-custom-control-text-colour);
  padding-right: 8px;
  margin-left: 12px;
  font-size: 1.3rem;
}