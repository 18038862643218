.UserProfileHeader {
  border-radius: .6rem .6rem 0 0;
  width: 100%;
  height: calc(16vw);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.UserProfileHeader-address {
  font-size: .9rem;
  color: rgb(126, 126, 126);
}

.UserProfileHeader-button-container {
  background-color: rgb(39, 39, 39);
  padding: .6rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.UserProfileHeader-buttons {
  display: flex;
}

.UserProfileHeader-community-container {
  background-color: rgb(37, 37, 37);
  padding: .6rem 1rem;
  display: flex;
  justify-content:right;
  align-items: center;
}

@media screen and (max-width: 1024px) {

}

@media screen and (max-width: 768px) {
  .UserProfileHeader {
    height: calc(18vw);
  }
}

@media screen and (max-width: 480px) {

}