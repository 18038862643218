.Proteus-hint-container {
  position: absolute; 
  z-index: 10; 
  width: 300px;
  background-color: var(--byowave-panel-bg-colour);
  top: 0;
  border-radius: 0 0 10px 0;
  transform: translateY(-105%);
  transition: .5s transform ease-in;
  padding: 1.2rem;
  box-shadow: rgba(0, 0, 0, 0.4) 3px 3px 5px;
}

.Proteus-hint-container.open {
  transform: translateY(0);
}

.Proteus-hint-icon {
  display: flex;
  justify-content: right;
}

.Proteus-hint-icon i {
  color: var(--byowave-alert-colour);
  font-size: 1.3rem;
}

.Proteus-hint-title {
  color: var(--byowave-cta-colour);
  font-size: 1.1rem;
  font-weight: bold;
  margin: .8rem 0 1.4rem 0;
}

.Proteus-hint-body {
  font-size: .9rem;
  line-height: 1.4rem;
  color: var(--byowave-paragraph-text-colour)
}

.Proteus-hint-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  font-size: 1.2rem;
  text-decoration: underline;
  font-style: italic;
  color: var(--byowave-cta-colour);
  margin-top: 1.6rem
}

.Proteus-hint-button:hover {
  color: var(--byowave-cta-hover-colour);
}