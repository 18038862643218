:root {
  --byowave-cta-colour: rgb(95, 106, 219);
  --byowave-cta-dark-colour: rgb(71, 76, 133);
  --byowave-cta-hover-colour: rgb(135, 144, 235);
  --byowave-cta-disabled-colour: rgb(45, 43, 53);
  --byowave-cta-text-colour: rgb(220, 220, 220);
  --byowave-cta-disabled-text-colour: rgb(68, 64, 84);  
  --byowave-alert-colour: rgb(124,15,208);
  --byowave-alert-text-colour: rgb(216, 175, 248);
  --byowave-paragraph-text-colour: rgb(178, 181, 212);
  --byowave-heading-text-colour: rgb(201, 204, 232);
  --byowave-panel-bg-colour: rgb(38,40,56);
  --byowave-panel-hover-bg-colour: rgb(51, 54, 75);
  --byowave-panel-text-colour: rgb(102, 104, 128);
  --byowave-panel-text-light-colour: rgb(103,106,133);
  --byowave-inputfield-bg-colour: rgb(25, 26, 34);
  --byowave-inputfield-text-colour: rgb(134, 139, 180);
  --byowave-inputfield-text-disabled-colour: rgb(121, 121, 121);
  --byowave-inputfield-placeholder-text-colour: rgb(63, 65, 82);
  --byowave-custom-control-border-colour: rgb(88,102,223);
  --byowave-custom-control-bg-colour: rgb(58,56,81);
  --byowave-custom-control-inner-colour: rgb(135,146,247);
  --byowave-custom-control-text-colour: rgb(132,136,168);
  --byowave-image-border-colour: rgb(81, 83, 103);
  --byowave-scrollbar-bg-colour: rgb(11,15,32);
  --byowave-scrollbar-handle-colour: rgb(81,111,248);
  --byowave-tab-bg-colour: rgb(35,34,58);
  --byowave-tab-selected-bg-colour: rgb(40,39,63);
  --byowave-tab-text-colour: rgb(99,102,147);
  --byowave-tab-selected-text-colour: rgb(114,118,169);
}

.Colour-white-bright { color: var(--byowave-heading-text-colour) }
.Colour-blue-bright { color: var(--byowave-cta-colour) }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Ropa Sans', sans-serif;
}

html {
  font-size: 14px;
  font-family: 'Ropa Sans', sans-serif;
  background-color: rgb(29,30,41);
  color: rgb(178,178,178);
}

body {
  width: 100%;
  height: 100%
}

ul {
  list-style-type: none;
  line-height: 1.6rem;
  color: rgb(178, 181, 212)
}

a {
  color: var(--byowave-cta-colour);
  text-decoration: none;
}

a:hover {
  color: var(--byowave-cta-hover-colour);
  text-decoration: none ;
}

p { 
  line-height: 1.6rem; 
  color: var(--byowave-paragraph-text-colour);
}

h1, h2, h3 { color: var(--byowave-heading-text-colour) }

.App-container {
  width: 100%;
  height: 100%;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
@keyframes fade-out { 0% { opacity: 1; } 100% { opacity: 0; } }
@keyframes fade-in { 0% { opacity: 0; } 100% { opacity: 1; } }
@keyframes fade { to { height: 0; opacity: 0; margin: 0; } }
@keyframes blinker { 50% { opacity: 0; } }
@keyframes bounce-stretch {
  0%   { transform: scale(1,1)    translateY(0); }
  10%  { transform: scale(1.1,.9) translateY(0); }
  30%  { transform: scale(.9,1.1) translateY(-100px); }
  50%  { transform: scale(1,1)    translateY(0); }
  57%  { transform: scale(1,1)    translateY(-7px); }
  64%  { transform: scale(1,1)    translateY(0); }
  100% { transform: scale(1,1)    translateY(0); }
}

.BounceStretch {
  animation-name: bounce-stretch;
  animation-timing-function: ease;
}

.BlinkMe {
  animation: blinker 1s step-start infinite;
}

.BlinkMeQuick {
  animation: blinker .3s step-start infinite;
}

.HideAfterDelay {
  height: auto;
  animation: fade 1s linear; 
  animation-delay: 2s; 
  animation-fill-mode: forwards;
}

.FadeInAfterDelay {
  height: auto;
  animation: fade-in .8s linear; 
  animation-delay: .5s; 
  animation-fill-mode: forwards;
  -webkit-animation: fade-in .8s;
  -moz-animation: fade-in .8s;
  -o-animation: fade-in .8s;
  -ms-animation: fade-in .8s;
  -webkit-animation-delay: .5s; 
  -moz-animation-delay: .5s; 
  -o-animation-delay: .5s; 
  -ms-animation-delay: .5s; 
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
}

.BreakLongText {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}

.AlertShow {
  opacity: 1;
  transition: all 250ms linear;
}

.AlertHide {
  opacity: 0;
  transition: all 250ms linear 2s;
}

.Button-standard {
  background-color: var(--byowave-cta-colour);
  border: none;
  border-radius: .5rem;
  padding: .6rem 1.2rem;
  color: var(--byowave-cta-text-colour);
  cursor: pointer;
  outline: none;
  font-weight: 600;
}

.Button-standard:hover {
  background-color: var(--byowave-cta-hover-colour);
}

.Button-standard-disabled {
  background-color: var(--byowave-cta-disabled-colour);
  border: none;
  border-radius: .5rem;
  padding: .6rem 1.2rem;
  color: var(--byowave-cta-disabled-text-colour);
  outline: none;
}

.Button-small {
  background-color: var(--byowave-cta-colour);
  border: none;
  border-radius: .4rem;
  padding: .3rem .8rem;
  color: var(--byowave-cta-text-colour);
  cursor: pointer;
  outline: none;
  font-weight: 400;
  font-size: .8rem;
}

.Button-small:hover {
  background-color: var(--byowave-cta-hover-colour);
}

.Button-small-disabled {
  background-color: var(--byowave-cta-disabled-colour);
  border: none;
  border-radius: .4rem;
  padding: .3rem .8rem;
  color: var(--byowave-cta-disabled-text-colour);
  outline: none;
  font-size: .9rem;
}

.Button-icon-small {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  font-size: .9rem;
}

.Button-icon-small i {
  font-size: 1.2rem;
  color: var(--byowave-cta-colour)
}

.Button-icon-small i:hover {
  color: var(--byowave-cta-hover-colour)
}

.Button-icon-small-selected {
  background: none;
  border: none;
  padding: 0;
  outline: none;
  font-size: .9rem;
}

.Button-text {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  font-size: 1rem;
  color: var(--byowave-cta-colour)
}

.Button-text:hover {
  color: var(--byowave-cta-hover-colour);
}

.Button-text-selected {
  background: none;
  border: none;
  padding: 0;
  outline: none;
  font-size: 1rem;
  color: var(--byowave-cta-hover-colour);
  text-decoration: underline;
}

::placeholder {
  color: var(--byowave-inputfield-placeholder-text-colour);
  padding: 0.6rem 0.5rem;
  font-size: 1rem;
}

.Textfield-dark {
  background-color: var(--byowave-inputfield-bg-colour);
  color: var(--byowave-inputfield-text-colour);
  border-radius: .4rem;
  border: none;
  outline: none;
  padding: 0.6rem 0.5rem;
  font-size: 1rem;
}

.Textfield-dark-disabled { 
  background-color: var(--byowave-inputfield-bg-colour);
  opacity: 0.5;
  color: var(--byowave-inputfield-text-disabled-colour);
  border-radius: .4rem;
  border: none;
  outline: none;
  padding: 0.6rem 0.5rem;
  font-size: 1rem;
}

.PanelLabel {
  background-color: var(--byowave-panel-bg-colour);
  padding: .8rem;
  font-size: .9rem;
  color: var(--byowave-panel-text-colour);
  line-height: 1.4rem;
  border-radius: 6px
}

.PanelAlert {
  background: var(--byowave-panel-bg-colour);
  color: var(--byowave-panel-text-light-colour);
  border: 1px solid var(--byowave-alert-colour);
  border-radius: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1.2rem;
  line-height: 1.4rem;
  box-shadow: rgba(0, 0, 0, 0.4) 3px 3px 5px;
}

.Formfield-error-inline {
  background-color: var(--byowave-alert-colour);
  padding: .4rem;
  color: var(--byowave-alert-text-colour);
  font-size: .9rem;
  border-radius: .4rem;
  margin-top: 2px;
}

.Tab-item-off {
  cursor: pointer;
  display: inline-block;
  color: rgb(100,100,100);
  padding: 0.5rem 1rem;
  background-image: linear-gradient(rgb(53, 53, 53), rgb(42,42,42));
  border-bottom: 1px solid rgb(32,32,32);
}

.Tab-item-off:hover {
  color: rgb(209, 209, 209);
}

.Tab-item-on {
  display: inline-block;
  color: rgb(209, 209, 209);
  padding: 0.5rem 1rem;
  border-radius: 6px 6px 0 0;
  background-image: linear-gradient(rgb(70,70,70), rgb(42,42,42));
}

form {
  margin: 0;
  padding: 0;
}

label {
  color:rgb(145, 145, 145);
  font-size: .9rem;
  padding-left: .4rem;
}

textarea {
  resize: none;
}

.RangeSlider {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}

input[type=range]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  background: rgba(50,50,50,1);
  height: 4px;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-moz-range-track {
  -moz-appearance: none;
  background: rgba(50,50,50,1);
  height: 4px;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border-radius: 20%;
  height: 14px;
  width: 14px;
  /* max-width: 40px; */
  position: relative;
  bottom: 6px;
  background-color: rgb(128,92,36);
  cursor: -webkit-grab;
}

input[type=range]::-moz-range-thumb {
  -moz-appearance: none;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  /* max-width: 40px; */
  position: relative;
  bottom: 6px;
  background-color: rgb(128,92,36);
  cursor: -moz-grab;
}

input[type=range]::-webkit-slider-thumb:active {
  cursor: -webkit-grabbing;
}

input[type=range]::-moz-range-thumb:active {
  cursor: -moz-grabbing;
}

/* SCROLL BARS */
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--byowave-scrollbar-handle-colour) var(--byowave-scrollbar-bg-colour);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: var(--byowave-scrollbar-bg-colour);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--byowave-scrollbar-handle-colour);
  border-radius: 20px;
  border: 3px solid var(--byowave-scrollbar-bg-colour);
}

@media screen and (max-width: 1024px) {

}

@media screen and (max-width: 768px) {

}

@media screen and (max-width: 480px) {

}
