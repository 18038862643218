.Proteus-container {
  width: 100%;
  height: calc(100vh - 30px);
  -webkit-user-select: none;
  user-select: none;
}

.Proteus-splashscreen-container {
  display: flex; 
  width: 80%;
  height: 90%;
  justify-content: center; 
  align-items: center;
  flex-direction: column;
  margin: auto
}

.Proteus-splashscreen-container img {
  max-width: 100%;
}

.Proteus-splashscreen-buttons {
  padding-top: 4rem;
}

.Proteus-splashscreen-version {
  width: 100%;
  text-align: right;
  padding-right: 24px;
  color: var(--byowave-panel-text-colour);
}

.Button-proteus {
  height: 34px;
  background: none;
  border: 1px solid var(--byowave-cta-dark-colour);
  padding: 0 1rem;
  color: var(--byowave-cta-text-colour);
  cursor: pointer;
  outline: none;
  transition: .3s;
  margin-left: 1rem
}

.Button-proteus:hover {
  background-color: var(--byowave-cta-hover-colour);
}

.Button-proteus-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  color: var(--byowave-cta-colour);
}

.Button-proteus-icon span {
  font-size: 1rem;
  padding-left: 6px;
  color: var(--byowave-cta-text-colour);
}

.Button-proteus-icon i {
  font-size: 20px;
  color: var(--byowave-cta-dark-colour);
}

.Proteus-connect-error-panel {
  display: flex;
  align-items: center;
  background-color: var(--byowave-panel-bg-colour);
  color: var(--byowave-alert-text-colour);
  border-radius: 3px;
  padding: .8rem;
  border: 1px solid var(--byowave-alert-colour);
}

.Proteus-connect-error-panel i {
  color: var(--byowave-alert-colour);
}

.Proteus-shell-error-panel {
  display: flex;
  align-items: center;
  background-color: var(--byowave-panel-bg-colour);
  color: var(--byowave-alert-text-colour);
  border-radius: 3px;
  padding: .5rem;
  border: 1px solid var(--byowave-alert-colour);
  margin-left: 10px;
}

.Proteus-shell-error-panel i {
  color: var(--byowave-alert-colour);
}

.Proteus-shell {
  width: 100%;
  height: 100%;
  background-color: rgb(29,30,41);
}

.Proteus-shell-header {
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(23,24,32);
  border-bottom: 1px solid rgb(9,9,9);
}

.Proteus-shell-header-logo-container {
  width: 33%;
}

.Proteus-shell-header-logo {
  display: flex; 
  flex-direction: column;
  width: 180px;
  justify-content: center; 
  align-items: center;
  margin-left: 16px
}

.Proteus-shell-header-version {
  text-align: center;
  height: 100%;
  width: 34%;
  padding-top: 10px;
  color: var(--byowave-panel-text-colour)
}

.Proteus-shell-header-avatar {
  text-align: right;
  height: 100%;
  width: 33%;
}

.Proteus-shell-footer {
  height: 30px;
  background-color: rgb(23,24,32);
  border-top: 1px solid rgb(42, 44, 58);
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  display: flex; 
  justify-content: flex-end; 
  align-items: center;
}

.Proteus-shell-footer-connected {
  color: var(--byowave-panel-text-colour);
  font-size: .8rem;
  display: flex; 
  align-items: center;
}

.Proteus-shell-workspace {
  height: calc(100% - 100px);
  padding: 20px;
}

.Proteus-shell-workspace-inner {
  width: 100%;
  height: 100%;
  background-color: rgb(32, 33, 44);
  border-radius: 10px;
}

.Proteus-shell-workspace-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  background-color: rgb(23, 24, 32);
  border-bottom: 1px solid rgb(11, 12, 16);
  border-radius: 10px 10px 0 0;
}

.Proteus-toolbar-button {
  height: 44px;
  width: 44px;
  background: none;
  border: 1px solid var(--byowave-cta-dark-colour);
  color: var(--byowave-cta-colour);
  cursor: pointer;
  outline: none;
  transition: .3s;
  margin-right: 7px;
}

.Proteus-toolbar-button:hover {
  background-color: var(--byowave-cta-dark-colour);
}

.Proteus-toolbar-button i {
  font-size: 26px;
  color: var(--byowave-cta-colour);
}

.Proteus-toolbar-button__selected {
  height: 44px;
  width: 44px;
  background: none;
  border: 1px solid var(--byowave-cta-dark-colour);
  background-color: var(--byowave-cta-hover-colour);
  color: var(--byowave-cta-dark-colour);
  outline: none;
  margin-right: 7px;
}

.Proteus-toolbar-button__disabled {
  height: 44px;
  width: 44px;
  background: none;
  border: 1px solid var(--byowave-cta-disabled-text-colour);
  background-color: var(--byowave-cta-disabled-colour);
  color: var(--byowave-cta-disabled-text-colour);
  outline: none;
  margin-right: 7px;
}

.Proteus-toolbar-button__disabled i {
  font-size: 26px;
  color: var(--byowave-cta-disabled-text-colour);
}

.Proteus-toolbar-button__selected i {
  font-size: 26px;
  color: var(--byowave-cta-dark-colour);
}

.Proteus-shell-workspace-unity {
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;
}

.Proteus-webgl-canvas {
  width: 100%;
  height: calc(100vh - 230px);
  border-radius: 0 0 10px 10px;
}