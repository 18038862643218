/*
  Shop Container
*/
.Shop-container {
  width: 80%; 
  margin: 2rem auto;
}

.Shop-breadcrumb-back {
  margin-top: 1.4rem
}

.Shop-breadcrumb-back i {
  color: var(--byowave-alert-colour);
  padding-right: .6rem;
}

/*
  Shop Product Summary
*/
.ShopProductSummary-container {
  background-color: var(--byowave-panel-bg-colour);
  padding: 1rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-top: 2rem;
}

.ShopProductSummary-container:hover {
  background-color: var(--byowave-panel-hover-bg-colour);
}

.ShopProductSummary-image-container {
  width: 150px;
  height: 150px;
  border: 2px solid var(--byowave-image-border-colour);
  border-radius: 3px;
}

.ShopProductSummary-image-container img {
  width: 100%;
  height: 100%
}

.ShopProductSummary-text-container {
  padding-left: 20px;
  height: 100%;
  width: calc(100% - 170px);
}

.ShopProductSummary-text-container h3 {
  color: var(--byowave-heading-text-colour);
  font-size: 1.4rem;
}

.ShopProductSummary-text-container p {
  padding-top: 10px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: pre-wrap;
  color: var(--byowave-panel-text-colour);
}

.ShopProductSummary-text-price {
  color: var(--byowave-cta-colour);
  font-size: 1.4rem;
  padding-top: 10px;
}

.ShopProductSummary-text-price-tax {
  color: var(--byowave-panel-text-colour);
  font-size: 1rem;
}

/*
  Shop Product
*/
.ShopProduct-container {
  display: flex;
  margin: 2rem 0
}

.ShopProduct-container-leftCol {
  width: 50%;
}

.ShopProduct-container-leftCol h3 {
  font-weight: normal;
  font-size: 1.4rem;
  color: var(--byowave-heading-text-colour);
}

.ShopProduct-container-leftCol p:nth-of-type(1) {
  color: var(--byowave-panel-text-colour);
  font-size: .8rem;
  padding-top: .2rem
}

.ShopProduct-container-leftCol p:nth-of-type(2) {
  color: var(--byowave-paragraph-text-colour);
  padding-top: 1rem
}

.ShopProduct-container-rightCol {
  width: 50%;
  padding-left: 2rem;
}

.ShopProduct-price-container {
  display: flex;
  justify-content: space-between;
  margin-top: 1.6rem;
}

.ShopProduct-price-container h3 {
  color: var(--byowave-cta-colour);
  font-weight: 500;
  font-size: 1.7rem;
}

.ShopProduct-price-container h3 > p {
  color: var(--byowave-cta-dark-colour);
  display: inline;
  font-size: 1.2rem;
  font-weight: lighter;
}

.ShopProduct-price-container p {
  color: var(--byowave-panel-text-colour);
  font-size: .8rem;
  padding-top: .2rem
}

.ShopProduct-image-carousel {
 border: 3px solid var(--byowave-custom-control-bg-colour);
 border-radius: 10px;
 background-color: var(--byowave-custom-control-bg-colour);
}

/*
  Shop Basket Product
*/
.ShopBasketProduct-container {
  background-color: var(--byowave-panel-bg-colour);
  padding: 1rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.ShopBasketProduct-image-container {
  width: 70px;
  height: 70px;
  border: 2px solid var(--byowave-image-border-colour);
  border-radius: 4px;
}

.ShopBasketProduct-image-container img {
  width: 100%;
  height: 100%
}

.ShopBasketProduct-text-container {
  padding-left: 1rem;
  width: calc(100% - 70px)
}

.ShopBasketProduct-text-container p:nth-of-type(1) {
  color: var(--byowave-panel-text-colour);
  font-size: .8rem;
}

.ShopBasketProduct-price-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%
}

.ShopBasketProduct-price-container p:nth-of-type(1) {
  color: var(--byowave-paragraph-text-colour);
  font-size: 1rem;
}

.ShopBasketProduct-price-container p:nth-of-type(2) {
  color: var(--byowave-panel-text-colour);
  font-size: .9rem;
}

/*
  Shop Checkout
*/
.ShopCheckout-container {
  display: flex;
  margin: 2rem 0
}

.ShopCheckout-container-leftCol {
  width: 50%;
  padding-right: 2rem;
}

.ShopCheckout-container-rightCol {
  width: 50%;
  padding-left: 2rem;
}

.ShopCheckout-help-container {
  background: var(--byowave-panel-bg-colour);
  color: var(--byowave-panel-text-colour);
  border: 1px solid var(--byowave-alert-colour);
  border-radius: 10px;
  width: 100%;
  padding: 1.2rem;
  margin-top: 2rem;
  box-shadow: rgba(0, 0, 0, 0.4) 3px 3px 5px;
}

.ShopCheckout-empty-container {
  width: 80%; 
  margin: auto;
  display: flex; 
  margin-top: 4rem;
}

.ShopCheckout-empty-leftCol {
  flex-basis: 0;
  flex-grow: 2;
  background-color: var(--byowave-alert-colour);
  border-radius: .6rem 0 0 .6rem;
  padding: 4rem;
  text-align: center;
}

.ShopCheckout-empty-rightCol {
  flex-basis: 0;
  flex-grow: 3;
  background-color: var(--byowave-panel-bg-colour);
  border-radius: 0 .6rem .6rem 0;
  padding: 4rem;
}

.ShopCheckout-empty-leftCol i {
  font-size: 12rem;
  color: rgb(235, 235, 235);
}

.ShopCheckout-summary-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 .6rem;
}

.ShopCheckout-summary-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .7rem;
}

.ShopCheckout-summary-spinner i {
  font-size: 5rem;
  color: var(--byowave-panel-text-colour);
  -webkit-animation:spin 1.5s linear infinite;
  -moz-animation:spin 1.5s linear infinite;
  animation:spin 1.5s linear infinite;
}

.ShopCheckout-breadcrumb-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ShopCheckout-breadcrumb-container i {
  padding: 0 1rem;
  font-size: 1.4rem;
  color: var(--byowave-cta-dark-colour)
}

.ShopCheckout-breadcrumb-link {
  color: var(--byowave-cta-colour);
  cursor: pointer
}

.ShopCheckout-breadcrumb-link:hover {
  color: var(--byowave-cta-hover-colour);
}

.ShopCheckout-breadcrumb-link__active {
  color: var(--byowave-heading-text-colour);
}

.ShopCheckout-breadcrumb-link__inactive {
  color: var(--byowave-cta-dark-colour);
}

/*
  Shop Payment
*/
.ShopPayment-stripelogo-container {
  width: 50%;
  margin-top: 2rem
}

.ShopPayment-stripelogo-container img {
  width: 100%
}

.ShopPayment-error {
  display: flex;
  align-items: center;
  padding: .7rem !important;
  margin-top: 1rem;
}

.ShopPayment-error i {
  color: var(--byowave-alert-colour);
  font-size: 1.8rem;
  margin-right: .6rem;
}

.ShopPayment-goobender-bounce-container {
  display: flex;
  height: 200px;
  width: 100%;
}

.ShopPayment-goobender-bounce {
  align-self: flex-end;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  height: 100px;
  margin: 0 auto 0 auto;
  transform-origin: bottom;
  width: 100px;
}

.ShopPayment-goobender-bounce img {
  width: 100%
}

/*
  Shop Payment Complete
*/
.ShopPaymentComplete-container {

}

.ShopPaymentComplete-success-container {
  display: flex;
  align-items: center;
  padding: 1rem !important;
  margin: 1.4rem 0;
}

.ShopPaymentComplete-success-container i {
  color: var(--byowave-alert-colour);
  font-size: 4rem;
  margin-right: .6rem;
}

/*
  Shop Basket
*/
.ShopBasket-container {
  display: flex;
  margin: 2rem 0
}

.ShopBasket-container-leftCol {
  width: 50%;
  padding-right: 2rem;
}

.ShopBasket-container-rightCol {
  width: 50%;
}

.ShopBasket-summary-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 .6rem;
}

@media screen and (max-width: 1024px) {
  .ShopCheckout-empty-container{
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  .ShopCheckout-empty-container {
    width: 90%;
  }

  .ShopCheckout-empty-leftCol i {
    font-size: 8rem;
  }
}

@media screen and (max-width: 480px) {
  .ShopCheckout-empty-container {
    flex-direction: column; 
    margin-top: 6rem
  }

  .ShopCheckout-empty-leftCol {
    display: none;
  }
}