.react-datepicker {
  background-color: var(--byowave-custom-control-bg-colour);
  border: 1px solid var(--byowave-custom-control-border-colour);
}

.react-datepicker__header {
  background-color: var(--byowave-panel-bg-colour);
  border-bottom: 0;
}
.react-datepicker__current-month {
  color: var(--byowave-cta-hover-colour)
}
.react-datepicker__day-name {
  color: var(--byowave-cta-hover-colour)
}
.react-datepicker__day {
  color: var(--byowave-cta-hover-colour)
}
.react-datepicker__day:hover {
  background-color: var(--byowave-cta-hover-colour);
  color: var(--byowave-cta-text-colour)
}

.react-datepicker__input-container input {
  background-color: var(--byowave-inputfield-bg-colour);
  color: var(--byowave-inputfield-text-colour);
  border-radius: .4rem;
  border: none;
  outline: none;
  padding: 0.6rem 0.5rem;
  font-size: 1rem;
  width: 100%;
  cursor: pointer
}

.react-datepicker__month-container {
  background-color: var(--byowave-custom-control-bg-colour);
  border: 0
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: var(--byowave-cta-colour);
  color: var(--byowave-cta-text-colour);
}
.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: var(--byowave-cta-hover-colour);
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: var(--byowave-cta-colour);
  color: var(--byowave-cta-text-colour);
}

.react-datepicker__navigation-icon {
  opacity: 0.5
}

.react-datepicker__triangle {
  opacity: 0;
}

.react-datepicker__close-icon::after {
  background-color: var(--byowave-cta-colour);
  color: var(--byowave-cta-text-colour);
}