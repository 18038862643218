.Proteus-gallery-container {
  position: absolute; 
  z-index: 10; 
  width: 260px;
  height: 100%;
  background-color: var(--byowave-tab-selected-bg-colour);
  top: 0;
  right: 0;
  border-radius: 0 0 10px 0;
  transform: translateX(105%);
  transition: .5s transform ease-in;
  box-shadow: rgba(0, 0, 0, 0.2) -3px 0px 5px;
}

.Proteus-gallery-container.open {
  transform: translateX(0);
}

.Proteus-gallery-header {
  background-color: rgb(23, 24, 32);
  border-bottom: 1px solid rgb(11, 11, 16);
  padding: 1.2rem;
  color: var(--byowave-cta-colour);
  font-size: 1.1rem;
  font-weight: bold;
}

.Proteus-gallery-save-wrapper {
  position: absolute; 
  z-index: 10; 
  top: 0;
  right: 260px;
  transform: translateY(-100%);
  transition: .5s transform ease-in;
}

.Proteus-gallery-save-wrapper.open {
  transform: translateY(0);
}

.Proteus-gallery-save-container {
  padding: 1rem;
  display: flex;
  align-items: center;
}

.Proteus-gallery-camera-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  transition: all 0.1s ease-in-out 0s
}

.Proteus-gallery-camera-button:hover {
  transform: scale(1.03);
}

.Proteus-gallery-camera-button i {
  font-size: 3.5rem;
  color: var(--byowave-cta-colour);
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 2px;
}

.Proteus-gallery-save-button {
  background-color: var(--byowave-cta-colour);
  border: none;
  padding: .5rem;
  cursor: pointer;
  outline: none;
  border-radius: 0 .4rem .4rem 0;
  color: var(--byowave-cta-text-colour);
}

.Proteus-gallery-save-button:hover {
  background-color: var(--byowave-cta-hover-colour);
}

.Proteus-gallery-save-inner {
  background-color: var(--byowave-panel-hover-bg-colour);
  padding: .4rem;
  margin: 5px 10px 0 0;
  border-radius: .6rem;
  display: flex;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.4) 3px 3px 5px;
}

.Proteus-gallery-save-textfield {
  background-color: var(--byowave-inputfield-bg-colour);
  color: var(--byowave-inputfield-text-colour);
  border-radius: .4rem 0 0 .4rem;
  border: none;
  outline: none;
  padding: .5rem;
  width: 250px
}

.Proteus-gallery-error-container {
  display: flex;
  align-items: center;
  background-color: var(--byowave-panel-bg-colour);
  border: 1px solid var(--byowave-alert-colour);
  color: var(--byowave-alert-text-colour);
  border-radius: 3px;
  margin: 0 13px 0 13px;
  padding: .5rem;
  box-shadow: rgba(0, 0, 0, 0.4) 3px 3px 5px;
  width: 310px
}

.Proteus-gallery-error-container i {
  color: var(--byowave-alert-colour);
}

.Proteus-gallery-save-progress-container {
  width: 300px;
  height: 24px
}

.Proteus-gallery-save-progress-inner {
  width: 0;
  height: 100%;
  background-color: var(--byowave-cta-colour)
}

.Proteus-gallery-tabs-container {
  width: 100%;
  display: flex;
  background-color: rgb(23,24,32);
}

.Proteus-gallery-tab {
  border-radius: 20px 20px 0 0;
  background-color: var(--byowave-tab-bg-colour);
  color: rgb(99,102,147);
  width: 121px;
  padding: 1rem;
  text-align: center;
  cursor: pointer
}

.Proteus-gallery-tab:hover {
  color: var(--byowave-tab-selected-text-colour);
  font-weight: bold;
}

.Proteus-gallery-tab__selected {
  border-radius: 20px 20px 0 0;
  background-color: var(--byowave-tab-selected-bg-colour);
  color: var(--byowave-tab-selected-text-colour);
  width: 121px;
  padding: 1rem;
  font-weight: bold;
  text-align: center;
}

.Proteus-gallery-inner-container {
  padding: .6rem .6rem;
  width: calc(100% - 14px);
  background-color: var(--byowave-tab-selected-bg-colour);
}

.Proteus-gallery-item-container {
  padding: 10px;
  background-color: var(--byowave-custom-control-bg-colour);
  margin-bottom: .8rem;
}

.Proteus-gallery-item-container img {
  width: 100%
}

.Proteus-gallery-item-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.Proteus-gallery-item-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 96%;
  padding-top: 5px;
  color: var(--byowave-paragraph-text-colour);
}

.Proteus-gallery-item-profile {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 60%;
  color: var(--byowave-cta-colour)
}

.Proteus-gallery-item-rating {
  width: 40%;
  text-align: right;
}

.Proteus-gallery-item-rating i {
  font-size: .8rem;
  padding-left: 2px;
  color: var(--byowave-cta-colour)
}